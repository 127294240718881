
::v-deep .el-button+.el-button {
  margin-left: 0px;
}
.xzClass{
  position: relative;
}
.xzClass .el-icon-edit{
  position: absolute;
  top:10px;
  right: 10px;
  color:red;
}


